import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

export interface MasterCompanyData {
  id: number;
  ja_pavadinimas: string;
  ja_kodas: string;
  vat_code: string;
  adresas: string;
  country_id: string;
  phone: string;
  mobile_phone: string;
  internet_page: string;
  bank: string;
  bank_account_number: string;
}

export interface MasterIbanResponse {
  status: boolean;
  message?: string;
  iban?: string;
  bankName?: string;
  branchName?: string;
  countryCode?: string;
}

@Injectable({ providedIn: 'root' })
export class MasterRegistryService {

  constructor(
    private Request: RequestService,
  ) {
  }

  checkCompanyCode (code: string): Promise<MasterCompanyData> {
    return this.Request.get("https://master.proman.lt/api/public/company/company_data?JA_kodas=" + code);
  }

  checkIbanCode(code: string): Promise<MasterIbanResponse> {
    return this.Request.get("https://master.proman.lt/api/public/iban/validate?iban=" + code);
  }
}
